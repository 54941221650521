import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { HashLink as Link } from 'react-router-hash-link';
import { useHistory, useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { domain } from '../../const';
import { FaBars, FaTimes } from 'react-icons/fa'
import buildReq from '../../request_builder';
import { Dropdown, Menu, Avatar, Button } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import "./style.css"

const Nav = ({setToggle, Logout, active, setActive, authenticated})=>{

    return (

        <ul>
            <li className={`${active === 'HOME' ? "active" : ""}`}>
                <Link to="/#intro" onClick={()=>{
                    setActive('HOME');
                    setToggle(false);
                }}>
                    Home
                </Link>
            </li>
            <li className={`${active === 'COURSES' ? "active" : ""}`}>
                <Link to="/#courses" onClick={()=>{
                    setActive('COURSES')
                    setToggle(false);
                }}>Courses</Link>
            </li>
            {   authenticated?
                <li className={`${active === 'SIGNUP' ? "active" : ""}`}>
                    <Link to="/my-courses">My Courses</Link>
                </li>
                :
                <li className={`${active === 'SIGNUP' ? "active" : ""}`}>
                    <Link to="/#signup" onClick={()=>{
                        setActive('SIGNUP')
                        setToggle(false);
                    }}>Subscribe</Link>
                </li>
            }
            <li className={`${active === 'FAQ' ? "active" : ""}`}>
                <Link to="/#faq" onClick={()=>{
                    setActive('FAQ')
                    setToggle(false);
                }}>FAQ</Link>
            </li>
            <li className={`${active === 'TESTIMONIALS' ? "active" : ""}`}>
                <Link  to="/#testimonials" onClick={()=>{
                    setActive('TESTIMONIALS')
                    setToggle(false);
                }}>Testimonials</Link>
            </li>
            <li className={`${active === 'RECRUITMENT' ? "active" : ""}`}>
                <Link  to="/#recruitment" onClick={()=>{
                    setActive('RECRUITMENT')
                    setToggle(false);
                }}>Recruiters</Link>
            </li>
            <li className={`${active === 'CONTACT' ? "active" : ""}`}>
                <Link to="/#contact" onClick={()=>{
                    setActive('CONTACT')
                    setToggle(false);
                }}>Contact Us</Link>
            </li>

            {/*
                authenticated ? 
                <li onClick={Logout}>
                    <Link to={{pathname : "/", hash : "#intro"}}>
                        Logout
                    </Link>
                </li>
                :
                <li>
                    <Link to="/login">Sign In / Sign Up</Link>
                </li>
            */}
        </ul>
    )
}

const menu = ({Logout})=>(
    <Menu>
        <Menu.Item>
            <Link to="/profile" className="text-decoration-none text-reset">My Profile</Link>
        </Menu.Item>
        <Menu.Item>
            <Link to="/contact" className="text-decoration-none text-reset">Contact Us</Link>
        </Menu.Item>
        <Menu.Item>
            <Button type="link" htmlType="button" onClick={()=>Logout()} className="text-decoration-none text-reset p-0">
                Logout
            </Button>
        </Menu.Item>
    </Menu>
);

const Nav2 = ({authenticated, Logout, active, setActive, setToggle})=>{

    return (

        <ul>
            {
                authenticated ?
                <React.Fragment>
                    <li className={`${active === 'MYCOURSE' ? "active" : ""}`}>
                        <Link 
                            to="/my-courses" onClick={()=>{
                                setActive('MYCOURSE');
                                setToggle(false);
                            }}
                        >
                            My Courses
                        </Link>
                    </li>
                    <li className={`${active === 'ALLCOURSE' ? "active" : ""}`}>
                        <Link 
                            to="/all-packages" onClick={()=>{
                                setActive('ALLCOURSE');
                                setToggle(false);
                            }}
                        >
                            All Courses
                        </Link>
                    </li>
                    <li>
                        <Dropdown overlay={menu({Logout})} className="ml-4">
                            <button className="btn btn-link ant-dropdown-link" onClick={e => e.preventDefault()}>
                            <Avatar icon={<UserOutlined style={{fontSize : 20}}/>} shape="circle" size={30} className="mr-2"/> 
                            {/* <span className="align-middle">{auth.name}</span>  */}
                            </button>
                        </Dropdown>
                    </li>
                </React.Fragment>
                :
                <React.Fragment>
                    {/* <li className={`${active === 'EXPLORE' ? "active" : ""}`}>
                        <Link 
                            to="/all-packages" onClick={()=>{
                                setActive('EXPLORE');
                                setToggle(false);
                            }}
                        >
                            Explore Packages
                        </Link>
                    </li> */}
                    {/* <li className={`${active === 'SIGNIN' ? "active" : ""}`}>
                        <Link 
                            to="/login" onClick={()=>{
                                setActive('SIGNIN');
                                setToggle(false);
                            }}
                        >
                            Signin / Signup
                        </Link>
                    </li> */}
                </React.Fragment>
            }

        </ul>
    )
}

function Header2({ auth, active, setActive, toggle, setToggle }){

    const cookie = useCookies();
    const [authenticated, setAuthenticated] = useState(auth?.token);
    const [hide, setHide] = useState(auth?.token);

    let history = useHistory();
    let location = useLocation();
    
    async function Logout(){    
        cookie[2]("auth",{
            domain : domain
        });
        await buildReq('/users/logout');
        let { from } = location.state || { from: { pathname: "/login" } };
        history.replace(from);
    }

    useEffect(()=>{
        setAuthenticated(auth?.token);
    },[auth, setAuthenticated]);

    useEffect(()=>{
        const blocked = [
            "/",
            "/privacy-policy",
            "/legal",
            "/funded_cybersecurity_training",
            "/careers"
        ]
        if(blocked.includes(location.pathname)){
            setHide(true);
        }
        else setHide(false);
    }, [location]);

    return(
        <header id="header" className={`fixed-top ${toggle ? 'mobile-nav-active' : ''}`}>
            <div className="container">

                <div className="logo float-left m-0">
                    <Link to="/#intro" className="scrollto h4 font-weight-bolder text-decoration-none">
                        <img src="/img/logo.png" alt="" className="img-fluid mr-2"/>Proper Devsecops
                    </Link>
                </div>

                {
                    hide ?
                        <React.Fragment>
                            <nav className="main-nav float-right d-none d-lg-block">
                                <Nav {...{setActive, Logout, authenticated, active, setToggle}}/>
                            </nav>

                            <nav className="mobile-nav d-lg-none">
                                <Nav {...{setActive, Logout, authenticated, active, setToggle}}/>
                            </nav>
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <nav className="main-nav float-right d-none d-lg-block">
                                <Nav2 {...{setActive, setToggle, Logout, authenticated, active}}/>
                            </nav>

                            <nav className="mobile-nav d-lg-none">
                                <Nav2 {...{setActive, setToggle, Logout, authenticated, active}}/>
                            </nav>
                        </React.Fragment>
                }
                <div className="mobile-nav-overly" style={{display : toggle ? "block" : "none"}}></div>

                <button type="button" className="mobile-nav-toggle d-lg-none">
                    {
                        toggle ?
                        <FaTimes onClick={()=>setToggle(false)}/> : <FaBars onClick={()=>setToggle(true)}/>
                    }
                </button>
            
            </div>
        </header>
    )
}

const mapStateToProps = (st)=>({auth : st.auth})
export default connect(mapStateToProps, null)(Header2);