import React, { useEffect, useState } from 'react';
import { Select, Button, Collapse } from 'antd';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import { MdMoneyOff, MdSchool } from 'react-icons/md';
import { FaLaptopCode } from 'react-icons/fa';
import { IoMdArrowDropright } from 'react-icons/io';
import { GoLocation, GoMail } from 'react-icons/go';
import { FiPhone } from 'react-icons/fi';

import { Helmet } from 'react-helmet-async';

import buildReq from '../../request_builder';
import { action } from '../../actions/mixpanel';
import Footer from '../footer';

import "./style.css"
import 'antd/dist/antd.css';
import Testimonial from '../testimonial';
import { Spinner } from 'react-bootstrap';
import Placements from '../Placements';

const { Option, OptGroup } = Select;
const { Panel } = Collapse;
function CourseCard(e) {
    const history = useHistory();
    return (
        <div className="col-md-6 col-lg-6 bounceInUp product-card">
            <div className="box">
                <img src={e.image} className="card-img-top img_max_height" alt={e.name} />
                {
                    e.isBestSeller &&
                    <h5 style={{ position: 'absolute', top: 20, left: 10 }}>
                        <span className="badge badge-pill badge-primary">Best Seller</span>
                    </h5>
                }
                <div className="card-body d-flex flex-column flex-fill">
                    <h4 className="title">
                        <Link to={e.learn_more ? e.learn_more : `/buy/${e._id}`}>
                            {e.name}
                        </Link>
                    </h4>
                    {e.title ? <p>{e.title}</p> : ''}
                    <ul>
                        {
                            Array.isArray(e.description) && e.description.map(d => (
                                <li key={d}>{d}</li>
                            ))
                        }
                    </ul>
                    {
                        e.learn_more ?
                            <p className="mt-auto h3 text-center">
                                <HashLink to={e.learn_more}>
                                    <Button type="primary" htmlType="button">Learn More</Button>
                                </HashLink>
                            </p>
                            :
                            <React.Fragment>
                                <p className="mt-auto h3 font-weight-bolder text-center">£{e.price}</p>
                                <p className="mt-3 h3 text-center">
                                    <Button type="primary" htmlType="button" onClick={() => history.push(`/buy/${e._id}`)}>Buy</Button>
                                </p>
                            </React.Fragment>
                    }
                </div>
            </div>
        </div>
    )
};

function Home({ active, toggle }) {

    const [course, setCourse] = useState("");
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [mobile, setMobile] = useState("");
    const [success, setSuccess] = useState("");
    const [err, setErr] = useState("");
    const [loading, setLoading] = useState(false);
    const [courses, setCourses] = useState([]);
    const [loadingCourses, setLoadingCourses] = useState(false);

    action.track("Home");

    const [contact, setContact] = useState({
        email: "",
        name: "",
        message: "",
        subject: "",
        suc_msg: "",
        err_msg: "",
        loading: false
    })

    const [recruiter, setRecruiter] = useState({
        email: "",
        name: "",
        message: "",
        mobile: '',
        loading: false,
        success: '',
        err: ''
    })

    const onClick = (ev) => {
        ev.preventDefault();
        let emailRegex = /^[^\s()<>@,;:/]+@\w[\w.-]+\.[a-z]{2,}$/i;

        setErr("");
        setSuccess("");

        if (email.length === 0 && !emailRegex.test(email)) {
            setErr("Please enter a Email Id");
            return;
        }

        if (name.length < 3) {
            setErr("Name should be at least 3 character long");
            return;
        }

        if (course.length === 0) {
            setErr("Name should be at least 3 character long");
            return;
        }

        setLoading(true)
        buildReq('/users/subscribe', 2, { email, name, course, mobile })
            .then(res => {
                setLoading(false);
                setSuccess("You have subscribed with us successfully, we have sent you some complementary material to help you prepare for Advanced Batches.");
                if (course === "GRC Specialist")
                    window.open("/CyBOK-version_1.0.pdf", '_blank');
                else if (course === "DevSecops, Cloud Security")
                    window.open("/Proper_DevSecops_Material.pdf", '_blank');
                else {
                    window.open("/study_material.zip", '_blank');
                }

                setName("");
                setEmail("");
                setMobile("");
                setCourse("");

            })
            .catch(err => {
                if (err.response)
                    setErr(err.response.data)
                else
                    setErr(err);
                setLoading(false);
            })
    }

    const onContact = (ev) => {
        ev.preventDefault();
        setContact({ ...contact, loading: true, suc_msg: "", err_msg: "" });
        if (contact.name.length < 8) {
            setContact({ ...contact, err_msg: "Password should be at least 8 character long", loading: false })
            return;
        }
        let emailRegex = /^[^\s()<>@,;:/]+@\w[\w.-]+\.[a-z]{2,}$/i;

        if (contact.email.length === 0 && !emailRegex.test(contact.email)) {
            setContact({ ...contact, err_msg: "Please enter a Email Id", loading: false })
            return;
        }

        if (contact.message.length < 0) {
            setContact({ ...contact, err_msg: "Please enter a message for us", loading: false });
            return;
        }

        setLoading(true)
        buildReq('/users/contact', 2, contact)
            .then(res => {
                setLoading(false);
                setContact({
                    ...contact,
                    suc_msg: "Your message has been sent successfully",
                    loading: false
                });
            })
            .catch(err => {
                console.log(err);
                setContact({
                    ...contact,
                    err_msg: "Some error occured while sending your message",
                    loading: false
                });
                setLoading(false);
            })
    }

    const onChange = (ev) => setRecruiter({ ...recruiter, [ev.target.name]: ev.target.value });

    const onRecruiterClick = (ev) => {
        ev.preventDefault();
        let emailRegex = /^[^\s()<>@,;:/]+@\w[\w.-]+\.[a-z]{2,}$/i;
        setRecruiter({ ...recruiter, err: '', success: '' })
        const { name, email, mobile, message } = recruiter;

        if (email.length === 0 && !emailRegex.test(email)) {
            setRecruiter({ ...recruiter, err: "Please enter a valid Email Id" });
            return;
        }

        if (name.length < 3) {
            setRecruiter({ ...recruiter, err: "Comment cannot be empty" });
            return;
        }

        if (mobile.length === 0) {
            setRecruiter({ ...recruiter, err: "Mobile Number cannot be empty" })
            return;
        }

        if (message.length === 0) {
            setRecruiter({ ...recruiter, err: "Comment cannot be empty" });
            return;
        }

        setRecruiter({ ...recruiter, loading: true })
        buildReq('/users/recruiter', 2, { email, name, message, mobile })
            .then(res => {
                setRecruiter({ ...recruiter, loading: false })
                setSuccess("thanks for sho");
                setRecruiter({
                    email: "",
                    name: "",
                    message: "",
                    mobile: '',
                    loading: false,
                    success: 'Thanks for showing interest we will send CVs soon',
                    err: ''
                });

            })
            .catch(err => {
                if (err.response)
                    setErr(err.response.data)
                else
                    setErr(err);
                setRecruiter({ ...recruiter, loading: false, err: err });
            })
    }

    useEffect(() => {
        async function getCourses() {
            setLoadingCourses(true);
            try {
                let data = await buildReq("/package", 1);
                if (Array.isArray(data.data)) {
                    let arr1 = [], arr2 = [];
                    for (let i = 0; i < data.data.length; i++) {
                        arr2.push(data.data[i]);
                        if (i % 2 === 1 || data.data.length - 1 === i) {
                            arr1.push(arr2);
                            arr2 = [];
                        }
                    }
                    setCourses(arr1);
                }
            }
            catch (error) {
                console.log(error);
            }
            finally {
                setLoadingCourses(false);
            }
        }
        getCourses();
    }, []);

    return (
        <div className='w-100 h-100'>

            {/* Intro Section */}

            <Helmet>
                <title>Proper Devsecops</title>
                <meta name="keywords" content="Proper Devsecops, Devops, Cyber Security, Cyber Security Training, Penetration Training, CEH certification, Vulnerability Management, Pentest" />
                <meta name="description" content="We help people to enter Cyber Security sector with training." />
                <meta name="title" content="Proper DevSecops" />
            </Helmet>

            <section id="intro" className="clearfix">
                <div className="container">

                    <div className="intro-img">
                        <img src="img/BANEER.png" alt="" className="img-fluid" />
                    </div>

                    <div className="intro-info">
                        <h2>
                            We help people<br />to enter<br />
                            <span>Cyber Security Sector</span>
                        </h2>
                        <div>
                            {/* <a href="#signup" className="btn-get-started scrollto">Get Started</a> */}
                            <HashLink className="btn-get-started" to="/funded_cybersecurity_training#intro">Funded Training</HashLink>
                            <a href="#courses" className="btn-services scrollto">Our Courses</a>
                        </div>
                        <p className="text-light">
                            Learn from experts and become a Devsecops Engineer, GRC Specialist, Cloud Security Architect.
                        </p>
                    </div>

                </div>

            </section>

            <section>
                <header className="my-5">&emsp;</header>
            </section>

            <main id="main">

                {/* Courses Section */}

                <section id="courses" className="section-bg">
                    <div className="container pb-5">

                        <header className="section-header">
                            <h3>Courses</h3>
                        </header>

                        {
                            loadingCourses
                                ?
                                <Spinner size='lg' variant="primary" />
                                :
                                Array.isArray(courses) && courses.length && courses.map((e, i) => (
                                    <div className={`row ${active === 'COURSES' ? "bounceInUp" : ""} mb-4`} key={i}>
                                        {
                                            e.map((c, j) => (
                                                <CourseCard {...c} key={`${i}-${j}`} />
                                            ))
                                        }
                                    </div>
                                ))
                        }

                    </div>
                </section>

                {/* Sign up Section */}

                <section id="signup">
                    <div className="container py-5">

                        <header className="section-header">
                            <h3>Subscribe</h3>
                            <p>Subscribe here with the course of your choice, we will contact you back.</p>
                        </header>

                        <div className="row row-eq-height justify-content-center">

                            <div className="text-primary mb-1 col-12 text-center">{success}</div>
                            <div className="text-danger mb-1 col-12 text-center">{err}</div>

                            <form className="w-50" id="signup" onSubmit={onClick}>

                                <div className="form-group">
                                    <label htmlFor="signup-course">Course</label>
                                    <Select defaultValue="" style={{ display: "block" }} value={course}
                                        className="form-control border-0 p-0" onChange={(val) => setCourse(val)}>
                                        <Option value="" disabled>
                                            Select a course...
                                        </Option>
                                        <OptGroup label="Starter Batch">
                                            <Option value="GRC Specialist">GRC Specialist</Option>
                                        </OptGroup>
                                        <OptGroup label="Advanced Batch">
                                            <Option value="DevSecops, Cloud Security">DevSecops, Cloud Security</Option>
                                        </OptGroup>
                                        <OptGroup label="Starter and Advanced Batch">
                                            <Option value="GRC + DevSecops + Cloud">GRC + DevSecops + Cloud</Option>
                                        </OptGroup>
                                    </Select>
                                    <div className="validation1" id="course-validation"></div>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="signup-name">Name</label>
                                    <input type="text" className="form-control"
                                        onChange={(ev) => setName(ev.target.value)} value={name}
                                    />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="signup-email">Email Address</label>
                                    <input type="email" className="form-control"
                                        onChange={(ev) => setEmail(ev.target.value)} value={email} />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="signup-mobile">Mobile Number (with country code)</label>
                                    <input type="tel" className="form-control"
                                        onChange={(ev) => setMobile(ev.target.value)} value={mobile} />
                                </div>

                                <Button type="primary" htmlType="submit" loading={loading} onClick={onClick} onSubmit={onClick}>Submit</Button>

                                <p className="text-primary mt-2" style={{ fontSize: "13px" }}>
                                    We will send a email with a PDF attachment which will help you to learn about security areas
                                    before you join our advanced training batch.
                                </p>
                            </form>

                        </div>

                    </div>
                </section>

                {/* FAQ Section */}
                <section id="faq">
                    <div className="container">
                        <div className={`row about-extra ${active === 'FAQ' ? "fadeInUp" : ""}`}>
                            <div className="col-lg-6 fadeInUp order-1 order-lg-2 text-center">
                                <img src="img/faq.png" className="img-fluid" alt="" />
                            </div>
                            <div className="col-lg-6 fadeInUp pt-4 pt-lg-0 order-2 order-lg-1">
                                <h4 className="text-light m-20">Frequently Asked Questions </h4>
                                <Collapse className="collapse-bg" bordered={false}
                                    expandIcon={({ isActive }) => <span><IoMdArrowDropright rotate={isActive ? 90 : 0} size={25} color='#fff' /></span>}
                                >
                                    <Panel className="panel-bg border-0 faq-panel"
                                        header={
                                            <span style={{ fontWeight: 500, fontSize: 18 }} className='text-light align-top'>
                                                I am into non IT background.Can i take this training to enter Cyber Security sector?
                                            </span>
                                        }
                                    >
                                        <p className="text-light panel-body">
                                            Yes,you can take our starter course which doesn't need any coding experience.
                                        </p>
                                    </Panel>
                                    <Panel className="panel-bg border-0 faq-panel"
                                        header={
                                            <span style={{ fontWeight: 500, fontSize: 18 }} className='text-light align-top'>
                                                How can i get more details about this course.
                                            </span>
                                        }
                                    >
                                        <p className=" text-light panel-body">
                                            We plan demos every week.Once you sign up we will inform you about future batches via email.
                                        </p>
                                    </Panel>
                                    <Panel className="panel-bg border-0 faq-panel"
                                        header={
                                            <span style={{ fontWeight: 500, fontSize: 18 }} className='text-light align-top'>
                                                Do you provide invoice?
                                            </span>
                                        }
                                    >
                                        <p className=" text-light panel-body">
                                            Yes we provide invoice once you confirm to collect the payment.
                                        </p>
                                    </Panel>
                                    <Panel className="panel-bg border-0 faq-panel"
                                        header={
                                            <span style={{ fontWeight: 500, fontSize: 18 }} className='text-light align-top'>
                                                Will you share all the training material?
                                            </span>
                                        }
                                    >
                                        <p className=" text-light panel-body">
                                            Yes,you will have unlimited lifetime access to our Google drive.
                                        </p>
                                    </Panel>
                                    <Panel className="panel-bg border-0 faq-panel"
                                        header={
                                            <span style={{ fontWeight: 500, fontSize: 18 }} className='text-light align-top'>
                                                What is course duration?
                                            </span>
                                        }
                                    >
                                        <p className=" text-light panel-body">
                                            Its a 2 month course.We do on weekdays and weekends.
                                        </p>
                                    </Panel>
                                </Collapse>
                            </div>
                        </div>
                    </div>
                </section>


                {/* Sign up Section */}

                <section id="clients">

                    <div className="container">

                        <div className="section-header">
                            <h3>Our Partners</h3>
                        </div>

                        <div className="row justify-content-center no-gutters clients-wrap clearfix wow fadeInUp">

                            <div className="col-lg-3 col-md-4 col-xs-12">
                                <div className="client-logo">
                                    <img
                                        src='/img/cyber101.jpeg'
                                        className="img-fluid"
                                        alt="Cyber 101"
                                    />
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-4 col-xs-12">
                                <div className="client-logo">
                                    <img
                                        src='/img/department_for_culture_media_and_sports.jpeg'
                                        className="img-fluid"
                                        alt="Department For Culture Media And Sport"
                                    />
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-4 col-xs-12">
                                <div className="client-logo">
                                    <img
                                        src='/img/cylon.jpg'
                                        className="img-fluid"
                                        alt="Cylon Hutzero"
                                    />
                                </div>
                            </div>

                        </div>

                    </div>

                </section>

                {/* Recruiter Sign up Section */}

                <section id="recruitment" className="section-bg">
                    <div className="container py-5">

                        <header className="section-header">
                            <h3>Hire our trained cyber security talent</h3>
                        </header>


                        <div className="row justify-content-center">

                            <div className="col-lg-6 col-12 order-2 order-lg-1 my-2">
                                <div className="icon-box d-flex align-items-center">
                                    <div className="icon"><MdMoneyOff size={30} /></div>
                                    <h6 className="title">
                                        Hire our trained cyber security talent without paying any recruitment fees.
                                        We don't get paid until our students get hired. We do everything to place them in jobs.
                                    </h6>
                                </div>

                                <div className="icon-box d-flex align-items-center">
                                    <div className="icon"><MdSchool size={30} /></div>
                                    <h6 className="title">
                                        Our students undergo rigorous training of 200+ hours in different&nbsp;
                                        cyber security frameworks, Application security, Dev Secops, Cloud security and Threat Modelling.
                                    </h6>
                                </div>

                                <div className="icon-box d-flex align-items-center">
                                    <div className="icon"><FaLaptopCode size={30} /></div>
                                    <h6 className="title">
                                        They have good technical skills and work quickly as soon as you hire them.
                                    </h6>
                                </div>
                            </div>

                            <div className="col-lg-6 col-12 order-1 order-lg-2">
                                <form onSubmit={onRecruiterClick}>

                                    <div className="text-primary mb-1">{recruiter.success}</div>
                                    <div className="text-danger mb-1">{recruiter.err}</div>

                                    <div className="form-group">
                                        <label htmlFor="name">Name</label>
                                        <input type="text" className="form-control" name="name"
                                            onChange={onChange} value={recruiter.name} />
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="email">Email Address</label>
                                        <input type="email" className="form-control" name="email"
                                            onChange={onChange} value={recruiter.email} />
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="mobile">Mobile Number (with country code)</label>
                                        <input type="tel" className="form-control" name="mobile"
                                            onChange={onChange} value={recruiter.mobile} />
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="message">Comment</label>
                                        <textarea rows={2} className="form-control" name="message"
                                            onChange={onChange} value={recruiter.message} style={{ maxWidth: '100%' }} />
                                    </div>

                                    <Button type="primary" htmlType="submit" loading={recruiter.loading}
                                        onClick={onRecruiterClick} onSubmit={onRecruiterClick}
                                    >
                                        Request CV's
                                    </Button>

                                </form>
                            </div>
                        </div>

                    </div>
                </section>

                {/* Testimonials Section */}
                <section id="testimonials">
                    <div className="container my-5">

                        <header className="section-header">
                            <h3>Testimonials</h3>
                        </header>

                        <Testimonial />

                    </div>
                </section>

                {/* Placements Section */}
                <section id="placements" className="section-bg">
                    <div className="container my-5">
                        <Placements />
                    </div>
                </section>

                {/* Contact Section */}

                <section id="contact">
                    <div className="container-fluid">

                        <div className="section-header">
                            <h3>Contact Us</h3>
                        </div>

                        <div className={`row ${active === 'CONTACT' ? "fadeInUp" : ""}`}>

                            <div className="col-lg-6">
                                <div className="map mb-4 mb-lg-0">
                                    <iframe title="Location"
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2503.826968865842!2d-0.1727134!3d51.1300996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4875f1ce36c44763%3A0xc03c88627ebbc446!2s3%20Churchill%20Ct%2C%20Manor%20Royal%2C%20Crawley%20RH10%209LU%2C%20UK!5e0!3m2!1sen!2sin!4v1651453790051!5m2!1sen!2sin"
                                        frameBorder="0" style={{ border: 0, width: "100%", height: "312px" }} allowFullScreen></iframe>
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className="row">
                                    <div className="col-md-4 info">
                                        <GoLocation />
                                        <p>
                                            Unitive Technologies <br />
                                            3 Churchill Ct, Manor Royal<br />
                                            Crawley, London<br />
                                            England, RH10 9LU<br />
                                        </p>
                                    </div>

                                    <div className="col-md-3 info">
                                        <FiPhone />
                                        <p>+447438295181</p>
                                    </div>

                                    <div className="col-md-5 info">
                                        <GoMail />
                                        <p>info@proper-devsecops.com</p>
                                    </div>
                                </div>

                                <div className="form">
                                    <div className="text-primary mb-1">{contact.suc_msg}</div>
                                    <div className="text-danger mb-1">{contact.err_msg}</div>

                                    <form id="contact-form" className="contactForm">
                                        <div className="form-row">
                                            <div className="form-group col-lg-6">
                                                <input type="text" className="form-control" id="contact-name" placeholder="Your Name" onChange={(ev) => {
                                                    setContact({ ...contact, name: ev.target.value })
                                                }} value={contact.name} />
                                                <div className="validation"></div>
                                            </div>
                                            <div className="form-group col-lg-6">
                                                <input type="email" className="form-control" id="contact-email" placeholder="Your Email" onChange={(ev) => {
                                                    setContact({ ...contact, email: ev.target.value })
                                                }} value={contact.email} />
                                                <div className="validation"></div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <input type="text" className="form-control" id="contact-subject" placeholder="Subject" onChange={(ev) => {
                                                setContact({ ...contact, subject: ev.target.value })
                                            }} value={contact.subject} />
                                            <div className="validation"></div>
                                        </div>
                                        <div className="form-group">
                                            <textarea className="form-control" rows="5" placeholder="Message" id="contact-msg" onChange={(ev) => {
                                                setContact({ ...contact, message: ev.target.value })
                                            }} value={contact.message} style={{ maxWidth: '100%' }}>
                                            </textarea>
                                            <div className="validation"></div>
                                        </div>
                                        <div className="text-center">
                                            <Button htmlType="submit" type="primary" loading={contact.loading} onClick={onContact} onSubmit={onContact}>Send Message</Button>
                                        </div>
                                    </form>

                                </div>
                            </div>

                        </div>

                    </div>
                </section>
            </main >

            {/* Footer */}

            <Footer />

        </div >
    )

}

const mapStateToProps = (st) => ({ auth: st.auth })
export default connect(mapStateToProps, null)(Home);