import React, { useEffect, useState } from 'react';
import { Select, Button, DatePicker, Collapse, Radio } from 'antd';
import buildReq from '../../request_builder';
import "./style.css"
import { action } from '../../actions/mixpanel';
import 'antd/dist/antd.css';
import { GiMoneyStack, GiTakeMyMoney } from 'react-icons/gi';
import { MdMoneyOff } from 'react-icons/md';
import { toast } from 'react-toastify';
import { HashLink } from 'react-router-hash-link';
import { IoMdArrowDropright } from 'react-icons/io';
import { Helmet } from 'react-helmet-async';
import Testimonial from '../testimonial';
import Placements from '../Placements';

const { Option } = Select;
const { Panel } = Collapse;

export default function Training({ toggle }) {

    const [form, setForm] = useState({
        first_name: '',
        last_name: '',
        email: '',
        mobile: '',
        country: '',
        gender: '',
        batch: '',
        payment_mode: '',
        date_of_birth: '',
        employment_history: '',
        last_annual_salary: 0,
        last_employment_role: '',
        _last_employment_role: '',
        years_of_experience: 0
    });
    const [success, setSuccess] = useState("");
    const [err, setErr] = useState("");
    const [loading, setLoading] = useState(false);

    action.track("funded training");

    const onClick = (ev) => {
        ev.preventDefault();
        let emailRegex = /^[^\s()<>@,;:/]+@\w[\w.-]+\.[a-z]{2,}$/i;

        setErr("");
        setSuccess("");

        const {
            first_name, last_name, mobile, email, country, gender, batch, payment_mode, date_of_birth,
            employment_history, last_annual_salary, last_employment_role, _last_employment_role, years_of_experience
        } = form;

        if (email.length === 0 && !emailRegex.test(email))
            return setErr("Please enter a Email Id");
        else if (first_name.length < 3)
            return setErr("Name should be at least 3 character long");
        else if (mobile.length === 0)
            return setErr("mobile number cannot be empty");
        else if (country.length === 0)
            return setErr("country cannot be empty");
        else if (gender.length === 0)
            setErr("please choose a gender");
        else if (batch.length === 0)
            return setErr("please select a timing for classes");
        else if (payment_mode.length === 0)
            return setErr("please select a payment mode");
        else if (date_of_birth.length === 0)
            return setErr("D.O.B cannot be empty");
        else if (!employment_history)
            return setErr("employment history cannot be empty");
        else if (employment_history === 'Yes') {
            if (!last_annual_salary) return setErr("last annual salary cannot be empty");
            if (!years_of_experience) return setErr("years of experience cannot be empty");
            else if (!last_employment_role) return setErr("last employment role cannot be empty");
            else if (last_employment_role === 'Other' && !_last_employment_role) return setErr("last employment role cannot be empty");
        }
        setLoading(true)
        buildReq('/users/free_training', 2, {
            first_name, last_name, mobile, email, country, gender, batch, payment_mode, date_of_birth,
            employment_history, last_annual_salary, last_employment_role, _last_employment_role, years_of_experience
        })
            .then(res => {
                toast.success("Thank you for showing interest we will get back to you shortly.", {
                    autoClose: 5000
                });
                setForm({
                    first_name: '',
                    last_name: '',
                    email: '',
                    mobile: '',
                    country: '',
                    gender: '',
                    batch: '',
                    payment_mode: '',
                    date_of_birth: '',
                    employment_history: '',
                    last_annual_salary: 0,
                    last_employment_role: '',
                    _last_employment_role: '',
                    years_of_experience: 0
                });
            })
            .catch(err => {
                if (err.response)
                    toast.error(err.response.data, {
                        autoClose: 5000
                    });
                else
                    setErr(err);
            })
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        if (err)
            toast.error(err, { autoClose: 5000 })
    }, [err]);

    const onChange = (ev) => {
        setForm({
            ...form,
            [ev.target.name]: ev.target.value
        })
    }

    const onSelectChange = (name) => (val) => setForm({ ...form, [name]: val });

    return (
        <div className={`w-100 h-100 ${toggle ? "mobile-nav-active" : ""}`}>

            <Helmet>
                <title>Funded Cyber Security Training</title>
                <meta name="keywords" content="Proper Devsecops, Funded Cyber Security Training, Future Earnings Agreement, Cyber Security Training, Penetration Training, CEH certification, Vulnerability Management, Pentest" />
                <meta name="description" content="Proper Devsecops now enables you to pay for your course with a share of your future earnings." />
                <meta name="title" content="Proper DevSecops" />
            </Helmet>

            {/* Intro Section */}

            <section id="intro" className="clearfix">
                <div className="container">
                    <div className="intro-info">
                        <h2>
                            Proper Devsecops now enables you to pay for your course with a share of your future earnings<br />
                            <span>– a “Future Earnings Agreement”.</span>
                        </h2>
                        <div className="text-center mt-3">
                            <HashLink className="btn-get-started" to="/funded_cybersecurity_training/#about">Learn More</HashLink>
                            <HashLink className="btn-get-started" to="/funded_cybersecurity_training/#enquire">Enquire Now</HashLink>
                            <HashLink className="btn-get-started" to="/funded_cybersecurity_training/#funded_training_faq">FAQ</HashLink>
                        </div>

                    </div>

                </div>

            </section>

            <section>
                <header className="my-5">&emsp;</header>
            </section>

            <main id="main">

                {/* About Section */}
                <section id="about_funded_training">
                    <div className="container" data-aos="fade-up">

                        <header className="section-header about_pad">
                            <h3>Future Earnings Agreement</h3>
                        </header>

                        <div className="row about-container">

                            <div className="col-lg-6 content order-lg-1 order-2 offset-lg-1">
                                <p>
                                    Instead of an upfront fee you can repay 12.5% of your earnings for 36 months
                                </p>

                                <div className="icon-box d-flex align-items-center">
                                    <div className="icon"><GiMoneyStack size={30} /></div>
                                    <h4 className="title">Only repay when you earn over £25,000 per year</h4>
                                </div>

                                <div className="icon-box d-flex align-items-center">
                                    <div className="icon"><GiTakeMyMoney size={30} className="" /></div>
                                    <h4 className="title">Never repay more than £18,000</h4>
                                </div>

                                <div className="icon-box d-flex align-items-center">
                                    <div className="icon"><MdMoneyOff size={30} /></div>
                                    <h4 className="title">Any balance remaining after 8 years is waived</h4>
                                </div>

                            </div>

                            <div className="col-lg-4 background order-lg-2" data-aos="zoom-in">
                                <img src="/img/fea.jpg" className="img-fluid" alt="" />
                            </div>
                        </div>

                        <h2 className="text-center mt-3">
                            You are protected.<br />
                            Regulated and approved by Financial Conduct Authority.
                        </h2>
                    </div>
                </section>

                {/* Sign up Section */}

                <section id="enquire" className="section-bg">
                    <div className="container">

                        <header className="section-header enquire_pad">
                            <h3>Enquire Now</h3>
                            <p>Fill the following form, we will contact you back.</p>
                        </header>

                        <div className="row row-eq-height justify-content-center">

                            <div className="text-primary mb-1 col-12 text-center">{success}</div>
                            <div className="text-danger mb-1 col-12 text-center">{err}</div>

                            <form className="w-50" onSubmit={onClick}>

                                <div className="form-group">
                                    <label htmlFor="first_name">First Name</label>
                                    <input type="text" className="form-control" name="first_name"
                                        onChange={onChange} value={form.first_name}
                                    />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="last_name">Last Name</label>
                                    <input type="text" className="form-control" name="last_name"
                                        onChange={onChange} value={form.last_name}
                                    />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="email">Email</label>
                                    <input type="text" className="form-control" name="email"
                                        onChange={onChange} value={form.email}
                                    />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="mobile">Mobile Number (with country code)</label>
                                    <input type="text" className="form-control" name="mobile"
                                        onChange={onChange} value={form.mobile}
                                    />
                                </div>

                                <div className="form-group">
                                    <label>Date Of Birth</label>
                                    <DatePicker className="w-100" format='DD/MM/YYYY'
                                        value={form.date_of_birth} onChange={onSelectChange('date_of_birth')} />
                                </div>

                                <div className="form-group">
                                    <label>Which country passport do you hold</label>
                                    <Select defaultValue="" className="select_box form-control border-0 p-0"
                                        value={form.country} onChange={onSelectChange('country')}>
                                        <Option value="" disabled></Option>
                                        <Option value='British'>British</Option>
                                        <Option value='Indefinite Leave to Remain (Britain)'>Indefinite Leave to Remain (Britain)</Option>
                                        <Option value='EU Passport'>EU Passport</Option>
                                        <Option value='Other'>Other</Option>
                                    </Select>
                                </div>

                                <div className="form-group">
                                    <label>Which day and timings are suitable for your training?</label>
                                    <Select defaultValue="" className="select_box form-control border-0 p-0"
                                        value={form.batch} onChange={onSelectChange('batch')}>
                                        <Option value="" disabled></Option>
                                        <Option value="Day time 9AM-3PM (Monday-Friday)">
                                            Day time 9AM-3PM (Monday-Friday)
                                        </Option>
                                        <Option value="Evening 6-9PM(Monday-Friday)">
                                            Evening 6-9PM(Monday-Friday)
                                        </Option>
                                        <Option value="Weekends 7AM-9PM (Saturday,Sunday)">
                                            Weekends 7AM-9PM (Saturday,Sunday)
                                        </Option>
                                    </Select>
                                </div>

                                <div className="form-group">
                                    <label>What gender category do you identify with?</label>
                                    <Select defaultValue="" className="select_box form-control border-0 p-0"
                                        value={form.gender} onChange={onSelectChange('gender')}>
                                        <Option value="" disabled></Option>
                                        <Option value="Male">
                                            Male
                                        </Option>
                                        <Option value="Female">
                                            Female
                                        </Option>
                                        <Option value="Prefer not to say">
                                            Prefer not to say
                                        </Option>
                                    </Select>
                                </div>

                                <div className="form-group">
                                    <label>How do you wish to pay for your training?</label>
                                    <Select defaultValue="" className="select_box form-control border-0 p-0"
                                        value={form.payment_mode} onChange={onSelectChange('payment_mode')}>
                                        <Option value="" disabled></Option>
                                        <Option value="Future Earnings Agreement (12.5% earnings over 36 months)">
                                            Future Earnings Agreement (12.5% earnings over 36 months)
                                        </Option>
                                        <Option value="Pay upfront (£9345)">
                                            Pay upfront (£9345)
                                        </Option>
                                        <Option value="Pay in installments (£9345)">
                                            Pay in installments (£9345)
                                        </Option>
                                    </Select>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="employment_history">Do you have any employment history?</label><br />
                                    <Radio.Group
                                        value={form.employment_history} name='employment_history'
                                        onChange={onChange}
                                    >
                                        <Radio value="Yes">Yes</Radio>
                                        <Radio value="No">No</Radio>
                                    </Radio.Group>
                                </div>

                                {
                                    form.employment_history === 'Yes' &&
                                    <React.Fragment>

                                        <div className="form-group">
                                            <label htmlFor="last_annual_salary">Last Annual salary</label>
                                            <input type='number' className="form-control" name="last_annual_salary"
                                                onChange={onChange} value={form.employment_history === 'Yes' ? form.last_annual_salary : ''}
                                            />
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="years_of_experience">Years of previous experience</label>
                                            <input type='number' className="form-control" name="years_of_experience"
                                                onChange={onChange} value={form.employment_history === 'Yes' ? form.years_of_experience : ''}
                                            />
                                        </div>

                                        <div className="form-group">
                                            <label>Last Employment role/Area</label>
                                            <Select defaultValue="" className="select_box form-control border-0 p-0"
                                                value={form.employment_history === 'Yes' ? form.last_employment_role : ''} onChange={onSelectChange('last_employment_role')}>
                                                <Option value="" disabled></Option>
                                                {
                                                    [
                                                        "Finance", "Marketing", "Product", "Operations", "Sales", "Law", "Engineering", "Analytics", "General Administration",
                                                        "IT (Information Technology)", "HR (Human Resources)", "Senior Management", "Other"
                                                    ]
                                                        .map((e, i) =>
                                                            <Option key={i} value={e}>{e}</Option>)
                                                }
                                            </Select>
                                        </div>

                                        {
                                            form.last_employment_role === 'Other' &&
                                            <div className="form-group">
                                                <label htmlFor="_last_employment_role">Employment Role</label>
                                                <input type='text' className="form-control" name="_last_employment_role"
                                                    onChange={onChange} value={form.employment_history === 'Yes' ? form._last_employment_role : ''}
                                                />
                                            </div>
                                        }

                                    </React.Fragment>
                                }

                                <div
                                    id="k-paywith-action"
                                    k-custom="green"
                                    k-provider-id="0014J00000ZAcDgQAL"
                                    k-course-id="ISGRC01"
                                    className={form.payment_mode === 'Pay in installments (£9345)' ? 'd-block' : 'd-none'}
                                >
                                </div>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    loading={loading}
                                    onClick={onClick}
                                    onSubmit={onClick}
                                    className={form.payment_mode === 'Pay in installments (£9345)' ? 'd-none' : 'd-block'}
                                >
                                    Submit
                                </Button>
                            </form>

                        </div>

                    </div>
                </section>

                {/* FAQ Section */}
                <section id="funded_training_faq">
                    <div className="container">

                        <div className='row about-extra FAQ'>

                            <div className="col-12 col-lg-10 offset-lg-1 fadeInUp pt-4 pt-lg-0 order-2 order-lg-1">
                                <h4 className="text-light m-20">Frequently Asked Questions </h4>
                                <Collapse className="collapse-bg" bordered={false}
                                    expandIcon={({ isActive }) => <span><IoMdArrowDropright rotate={isActive ? 90 : 0} size={25} color='#fff' /></span>}
                                >
                                    <Panel className="panel-bg border-0 faq-panel"
                                        header={<h6 className="text-light mb-0">What is a Future Earnings Agreement?</h6>}>
                                        <p className=" text-light panel-body">
                                            A Future Earnings Agreement (FEA) allows you to defer the cost of your qualification until you are earning above a minimum income threshold.
                                            This will be repaid in future by sharing a small percent of your salary for a defined period up to a maximum amount, so risk is shared, and repayments are more affordable. If your salary goes down or is less than you expect, you will repay less, if your salary is more than you expect you will pay more. The beauty of this is, because the total amount you repay is capped, if you pay more than you expect you may pay your loan off earlier, but if you pay less than you expect you won’t have to make any extra repayments.
                                        </p>
                                    </Panel>
                                    <Panel className="panel-bg border-0 faq-panel"
                                        header={<h6 className="text-light mb-0">How is your training structured? Can I take any certifications exams outside after going through your training?</h6>}>
                                        <p className=" text-light panel-body">
                                            Our training covers different cyber security frameworks, Governance, risk &amp; Compliance, computer network fundamentals, cyber security process & policy, GDPR,PCI, Dev-Secops, Application security, Threats, Cloud security, Third party supplier assurance and Threat Modelling. We have weekday and weekend batches. We train for 200 hours in total.
                                            <br />
                                            Our training will help you to clear below exams:
                                            CompTIA Security +, CompTIA Network +,ISO 27001 Foundation, ISO 27001 Lead Implementer, CEH,PCI certification, CISA,CCSP,CCSK,CISSP.

                                        </p>
                                    </Panel>
                                    <Panel className="panel-bg border-0 faq-panel"
                                        header={<h6 className="text-light mb-0">Do you help us in getting jobs?</h6>}>
                                        <p className=" text-light panel-body">
                                            Yes. If you opt for a Future Earning agreement, we will help you by preparing CV, mock interviews, sharing available jobs from our connections and making connections with recruiters.
                                        </p>
                                    </Panel>
                                    <Panel className="panel-bg border-0 faq-panel"
                                        header={<h6 className="text-light mb-0">How will I be notified if I am selected into your funded cyber security training batch?</h6>}>
                                        <p className=" text-light panel-body">
                                            You will receive an email if you get selected for our batches within 4 weeks after submitting above enquiry form. If you don't receive any email from us within 4 weeks after submission of enquiry form, you should assume that you are not selected and try again for the next batch. We start new batches frequently every 3 months.
                                        </p>
                                    </Panel>
                                    <Panel className="panel-bg border-0 faq-panel"
                                        header={<h6 className="text-light mb-0">How many students do you select into each of these funded training batches?</h6>}>
                                        <p className=" text-light panel-body">
                                            We select 20 students every 3 months for funded training batch.
                                        </p>
                                    </Panel>
                                    <Panel className="panel-bg border-0 faq-panel"
                                        header={<h6 className="text-light mb-0">How do you select students into this funded cyber security training batch?</h6>}>
                                        <p className=" text-light panel-body">
                                            We look at previous experience, country of origin, affordability and select candidates who can easily secure jobs through our funded training. We also encourage women and students to get into the cyber security sector.
                                        </p>
                                    </Panel>
                                    <Panel className="panel-bg border-0 faq-panel"
                                        header={<h6 className="text-light mb-0">I am not a UK/EU citizen? Will I be taken into your batch?</h6>}>
                                        <p className=" text-light panel-body">
                                            There are less chances of funding you for this training right now. We review candidates' previous experience and see if they can get a job and take risk in funding those people.
                                        </p>
                                    </Panel>
                                    <Panel className="panel-bg border-0 faq-panel"
                                        header={<h6 className="text-light mb-0">Do I have to take the first job I am offered?</h6>}>
                                        <p className=" text-light panel-body">
                                            No. We will work with you to find suitable opportunities.
                                        </p>
                                    </Panel>
                                    <Panel className="panel-bg border-0 faq-panel"
                                        header={<h6 className="text-light mb-0">Can I re-pay full training cost once I get employed? I do not want to pay training cost in installments for 36 months. Can I settle total money once I get a job?</h6>}>
                                        <p className=" text-light panel-body">
                                            Yes.<br />
                                            We take two times the training cost if you pay us once you get employed.
                                        </p>
                                    </Panel>
                                </Collapse>
                            </div>

                        </div>

                    </div>
                </section>

                {/* Testimonials Section */}
                <section id="testimonials">
                    <div className="container my-5">

                        <header className="section-header">
                            <h3>Testimonials</h3>
                        </header>

                        <Testimonial />

                    </div>
                </section>

                {/* Placements Section */}
                <section id="placements" className="section-bg">
                    <div className="container my-5">
                        <Placements />
                    </div>
                </section>

            </main >
            <footer id="footer" >
                <div className="container">
                    <div className="copyright">
                        &copy; Copyright <strong>Unitive Technologies</strong>. All Rights Reserved
                    </div>
                </div>
            </footer >
        </div >
    )

}