import React from 'react';
import { FaLinkedin, FaTwitter } from 'react-icons/fa'
import { HashLink } from 'react-router-hash-link';

export default function Footer(){
    return (
        <footer id="footer" >
            <div className="footer-top">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-4 col-md-6 offset-lg-1 footer-info">
                            <h3>Proper Devsecops</h3>
                            <p>
                                We help people to enter Cyber Security Sector. Learn from experts and become a Devsecops Engineer,
                                GRC Specialist,Cloud Security Architect.
                            </p>
                            <img src='/img/Cyber_Essentials.png' alt="Cyber Essentials Plus" className="w-25 mt-3" />
                        </div>

                        <div className="col-lg-3 col-md-6 footer-links px-lg-5">
                            <h4>Useful Links</h4>
                            <ul>
                                <li><HashLink to={`/#intro`}>Home</HashLink></li>
                                <li><HashLink to={`/#faq`}>FAQ</HashLink></li>
                                <li><HashLink to={`/funded_cybersecurity_training/#intro`}>Funded Training</HashLink></li>
                                <li><HashLink to={`/careers/#career`}>Careers</HashLink></li>
                                <li><HashLink to={`/privacy-policy/#privacy_policy`}>Privacy Policy</HashLink></li>
                                <li><HashLink to={`/legal/#legal`}>Terms And Conditions</HashLink></li>
                            </ul>
                        </div>

                        <div className="col-lg-4 col-md-6 footer-contact">
                            <h4>Contact Us</h4>
                            <p>
                                Unitive Technologies <br />
                                3 Churchill Ct, Manor Royal<br />
                                Crawley, London<br />
                                England, RH10 9LU<br />
                                <strong>Phone:</strong> +447438295181<br />
                                <strong>Email :</strong> info@proper-devsecops.com<br />
                            </p>

                            <div className="social-links">
                                <a href="https://www.linkedin.com/company/properdevsecops/" className="linkedin">
                                    <FaLinkedin />
                                </a>
                                <a href="https://twitter.com/DevsecopsProper" className="linkedin">
                                    <FaTwitter />
                                </a>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className="container">
                <div className="copyright">
                    &copy; Copyright <strong>Unitive Technologies</strong>. All Rights Reserved
                </div>
            </div>
        </footer >
    )
}