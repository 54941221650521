import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { CookiesProvider } from 'react-cookie';
import { ToastContainer } from 'react-toastify';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { HelmetProvider } from 'react-helmet-async';

import store from './store';
import Router from './router';
import { stripeKey } from './const';

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';

const stripePromise = loadStripe(stripeKey);

function App() {
	return (
		<Suspense fallback={<div>Loading ...</div>}>
			<HelmetProvider>
				<Elements stripe={stripePromise}>
					<CookiesProvider>
						<Provider store={store}>
							<Router />
							<ToastContainer
								position="top-center"
								autoClose={3000}
								hideProgressBar={true}
								newestOnTop={false}
								closeOnClick
								rtl={false}
								bodyClassName="font"
								pauseOnFocusLoss={false}
							/>
						</Provider>
					</CookiesProvider>
				</Elements>
			</HelmetProvider>
		</Suspense>
	);
}

export default App;