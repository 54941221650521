import React, { useState, useEffect, lazy } from "react";
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Redirect,
} from "react-router-dom";
import { useDispatch } from 'react-redux'
import { useCookies } from 'react-cookie';

import Header from './components/header';
import Home from "./components/home";
import Training from "./components/freeCyberSecurityTraining";
import Consultation from './components/Consultation';
import Careers from "./components/careers";

import { loginAction, logoutAction } from './actions/auth';
import { action } from "./actions/mixpanel";

const Login = lazy(() => import('./components/login'));
const Register = lazy(() => import('./components/register'));
const PackageList = lazy(() => import('./components/packageList'));
const Package = lazy(() => import('./components/package'));
const Course = lazy(() => import('./components/course'));
const MyCourses = lazy(() => import('./components/mycourses'));
const Profile = lazy(() => import('./components/profile'));
const Order = lazy(() => import('./components/order'));
const ForgotPass = lazy(() => import('./components/forgotPass'));
const Reset = lazy(() => import('./components/resetPass'));
const Privacy = lazy(() => import('./components/privacyPolicy'));
const Legal = lazy(() => import('./components/legal'));
const ContactForm = lazy(() => import('./components/contact'));

function WithAuth({ Component, token, ...props }) {
	return (
		<Route {...props}
			render={({ location }) => (
				token ? (
					<Component />
				)
					:
					<Redirect to={{ pathname: "/login", state: { from: location } }} />
			)}
		/>
	)
}

function WithoutAuth({ Component, token, ...props }) {
	return (
		<Route {...props}
			render={({ location }) => (
				token ?
					<Redirect to={{ pathname: "/", state: { from: location } }} />
					:
					<Component />
			)}
		/>
	)
}

export default function App() {

	const [cookie] = useCookies(['auth']);
	const [authenticated, setAuthenticated] = useState(cookie.auth?.token);

	const [toggle, setToggle] = useState(false);
	const [active, setActive] = useState("HOME");
	const dispatch = useDispatch();

	useEffect(() => {
		if (cookie.auth && cookie.auth.token)
			dispatch(loginAction(cookie.auth));
		if (cookie.auth && cookie.auth.id)
			action.identify(cookie.auth.id)
	})

	useEffect(() => {
		setAuthenticated(cookie.auth?.token)
		if (cookie.auth?.token) {
			dispatch(loginAction(cookie.auth));
		} else {
			dispatch(logoutAction());
		}
	}, [cookie, dispatch]);

	return (
		<Router>
			<div className="h-100 w-100 d-flex flex-column">

				<Header  {...{ active, toggle, setActive, setToggle }} />
				<div className="flex-fill d-flex" id="body">
					<Switch>

						<WithoutAuth path="/login" exact Component={Login} token={authenticated} />
						<WithoutAuth path="/register" exact Component={Register} token={authenticated} />
						<WithoutAuth path="/reset/:token/:verify" exact Component={Reset} token={authenticated} />
						<WithoutAuth path="/forgot_pass" exact Component={ForgotPass} token={authenticated} />
						<WithoutAuth path="/funded_cybersecurity_training" exact Component={Training} token={authenticated} />
						<WithoutAuth path="/consultation" exact Component={Consultation} token={authenticated} />
						<Route path="/home" exact>
							<PackageList />
						</Route>
						<Route path="/all-packages" exact>
							<PackageList />
						</Route>
						<Route path="/package/:id" exact>
							<Package />
						</Route>
						<Route path="/buy/:id" exact>
							<Order />
						</Route>
						<Route path="/course/:id" exact>
							<Course />
						</Route>
						<Route path="/privacy-policy" exact {...{ active, toggle }}>
							<Privacy />
						</Route>
						<Route path="/careers" exact>
							<Careers />
						</Route>
						<Route path="/legal" exact {...{ active, toggle }}>
							<Legal />
						</Route>
						<WithAuth path="/profile" exact Component={Profile} token={authenticated} />
						<WithAuth path="/my-courses" exact Component={MyCourses} token={authenticated} />
						<WithAuth path="/contact" exact Component={ContactForm} token={authenticated} />
						<Route path="/">
							<Home  {...{ active, toggle }} />
						</Route>
					</Switch>
				</div>
			</div>
		</Router>
	);
}