import React from 'react';
import { Helmet } from 'react-helmet-async';
import Footer from '../footer';

export default function Careers() {

    return (
        <div className="w-100 h-100" id='career'>

            <Helmet>
                <script type="application/ld+json">
                    {`{
                        "@context": "https://schema.org/",
                        "@type": "JobPosting",
                        "title" : "Cyber Security Trainer",
			            "description" : "<p> CyberRMP is a cyber security company specialized in Governance Risk and Compliance for legacy and cloud environments. We specialise in application security and cloud security. We are looking for IT trainer with real time experience. Trainers should have good communication skills and have structured approach towards training.<br /> We are looking for trainer to provide online training on technologies like</p>",
                        "identifier": {
                            "@type": "PropertyValue",
                            "name": "CyberRMP",
                            "propertyId": "https://cyberrmp.com"
                        },
                        "datePosted": "2020-10-30",
                        "validThrough": "2020-12-16T00:00",
                        "employmentType": ["FULL_TIME", "PART_TIME", "TEMPORARY"],
                        "hiringOrganization": {
                            "@type": "Organization",
                            "name": "CyberRMP",
                            "sameAs": "https://www.cyberrmp.com",
                            "logo": "https://cyberrmp.com/img/logo1.jpg"
                        },
                        "jobLocationType": "TELECOMMUTE",
                        "baseSalary": {
                            "@type": "MonetaryAmount",
                            "currency": "GBP",
                            "value": {
                                "@type": "QuantitativeValue",
                                "minValue": 50.00,
                                "maxValue": 100.00,
                                "unitText": "HOUR"
                            }
                        }
                    }`}
                </script>
            </Helmet>

            <section className="container my-5">
                <h4>Available Positions</h4>

                <h5>
                    Cyber Security Trainer
            </h5>
                <p>
                    Proper DevSecops is a specialist cyber security training company. We specialize on end user and
                    corporate training. We have trained more than 200 students in last 2 years. We are looking for IT
                    trainer with real time experience. Trainers should have good communication skills and have
                    structured approach towards training.
                <br />
                We are looking for trainer to provide online training on technologies like
            </p>
                <ol>
                    <li>Devops</li>
                    <li>Cloud(AWS/Azure/GCP)</li>
                    <li>DevSecops</li>
                    <li>Application security</li>
                    <li>Cloud Security</li>
                    <li>Cyber security and Information security</li>
                </ol>
                <p>
                    Tier 2 visa holders can also apply for this position.
            </p>
                <div>
                    <strong>Contract length:</strong> 6 months<br />
                    <strong>Part-time hours:</strong> 40 per week<br />
                    <strong>Application deadline:</strong> 16/12/2020<br />
                    <strong>Expected start date:</strong> 11/01/2021<br />
                    <strong>Job Types:</strong> Full-time, Part-time, Contract, Commission<br />
                    <strong>Salary:</strong> £50.00-£100.00 per hour<br />
                    <strong>Benefits:</strong>
                    <ul>
                        <li>Work from home</li>
                    </ul>
                    <strong>Schedule:</strong>
                    <ul>
                        <li>8 hour shift</li>
                        <li>Weekends</li>
                    </ul>
                    <strong>Experience:</strong> Cyber Security: 4 years (Preferred)<br />
                    <strong>Work remotely:</strong> Yes<br />
                </div>
            </section>
            <Footer />
        </div>
    )
}