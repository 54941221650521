import mixpanel from 'mixpanel-browser';
import { mixpanelKey } from '../const';

if(mixpanelKey)
    mixpanel.init(mixpanelKey);
export const action = {
    track : (event, payload)=>{
        if(mixpanelKey)
            mixpanel.track(event, payload);
    },
    identify : (payload)=>{
        if(mixpanelKey)
            mixpanel.identify(payload);
    },
    people : (payload) => {
        if(mixpanelKey)
            mixpanel.people.set({
                "$email" : payload.email
            })
    }
}