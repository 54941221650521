import React, { useEffect, useState } from 'react';
import { Button, DatePicker } from 'antd';
import buildReq from '../../request_builder';
import "./style.css"
import { action } from '../../actions/mixpanel';
import 'antd/dist/antd.css';
import { toast } from 'react-toastify';
import { HashLink } from 'react-router-hash-link';

export default function Consultation({ toggle }) {

    const [form, setForm] = useState({
        first_name: '',
        last_name: '',
        email: '',
        mobile: '',
        total_hours: 0,
        date: '',
        description: ''
    });
    const [success, setSuccess] = useState("");
    const [err, setErr] = useState("");
    const [loading, setLoading] = useState(false);

    action.track("consultation");

    const onClick = (ev) => {
        ev.preventDefault();
        let emailRegex = /^[^\s()<>@,;:/]+@\w[\w.-]+\.[a-z]{2,}$/i;

        setErr("");
        setSuccess("");

        const {
            first_name, last_name, mobile, email, date, total_hours, description } = form;

        if (email.length === 0 && !emailRegex.test(email))
            return setErr("Please enter a Email Id");
        else if (first_name.length < 3)
            return setErr("Name should be at least 3 character long");
        else if (mobile.length === 0)
            return setErr("mobile number cannot be empty");
        else if (description.length === 0)
            return setErr("Please tell us what you need help with ?");
        else if (date === 0)
            return setErr("Date of consultation cannot be empty");
        else if (!total_hours) return setErr("total hours cannot be empty");

        setLoading(true)
        buildReq('/users/consultation', 2, {
            first_name, last_name, mobile, email, date, total_hours, description
        })
            .then(res => {
                toast.success("Thank you for showing interest we will get back to you shortly.", {
                    autoClose: 5000
                });
                setForm({
                    first_name: '',
                    last_name: '',
                    email: '',
                    mobile: '',
                    total_hours: 0,
                    date: '',
                    description: ''
                });
            })
            .catch(err => {
                if (err.response)
                    toast.error(err.response.data, {
                        autoClose: 5000
                    });
                else
                    setErr(err);
            })
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        if (err)
            toast.error(err, { autoClose: 5000 })
    }, [err]);

    const onChange = (ev) => {
        setForm({
            ...form,
            [ev.target.name]: ev.target.value
        })
    }

    const onSelectChange = (name) => (val) => setForm({ ...form, [name]: val });

    return (
        <div className={`w-100 h-100 ${toggle ? "mobile-nav-active" : ""}`}>

            {/* Intro Section */}

            <section id="intro" className="clearfix">
                <div className="container">
                    <div className="intro-info">
                        <h2 className='text-center'>
                            Book a cyber security consultation
                        </h2>
                        <div className="text-center mt-3">
                            <HashLink className="btn-get-started" to="/consultation/#enquire">Enquire Now</HashLink>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <header className="my-5">&emsp;</header>
            </section>

            <main id="main">

                {/* Sign up Section */}

                <section id="enquire" className="section-bg">
                    <div className="container">

                        <header className="section-header enquire_pad">
                            <h3>Enquire Now</h3>
                            <p>Fill the following form, we will contact you back.</p>
                        </header>

                        <div className="row row-eq-height justify-content-center">

                            <div className="text-primary mb-1 col-12 text-center">{success}</div>
                            <div className="text-danger mb-1 col-12 text-center">{err}</div>

                            <form className="w-50" onSubmit={onClick}>

                                <div className="form-group">
                                    <label htmlFor="first_name">First Name</label>
                                    <input type="text" className="form-control" name="first_name"
                                        onChange={onChange} value={form.first_name}
                                    />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="last_name">Last Name</label>
                                    <input type="text" className="form-control" name="last_name"
                                        onChange={onChange} value={form.last_name}
                                    />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="email">Email</label>
                                    <input type="text" className="form-control" name="email"
                                        onChange={onChange} value={form.email}
                                    />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="mobile">Mobile Number (with country code)</label>
                                    <input type="text" className="form-control" name="mobile"
                                        onChange={onChange} value={form.mobile}
                                    />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="total_hours">Total hours of consultation required</label>
                                    <input type='number' className="form-control" name="total_hours"
                                        onChange={onChange} value={form.total_hours}
                                    />
                                </div>

                                <div className="form-group">
                                    <label>Date Of Consultation</label>
                                    <DatePicker
                                        className="w-100" format='DD/MM/YYYY'
                                        value={form.date} onChange={onSelectChange('date')}
                                    />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="description">How can we help you ?</label>
                                    <textarea
                                        className="form-control" rows="5" id="description"
                                        onChange={onChange} value={form.description} name='description'
                                    >
                                    </textarea>
                                </div>


                                <Button type="primary" htmlType="submit" loading={loading} onClick={onClick} onSubmit={onClick}>Submit</Button>

                            </form>

                        </div>

                    </div>
                </section>

            </main >
            <footer id="footer" >
                <div className="container">
                    <div className="copyright">
                        &copy; Copyright <strong>Unitive Technologies</strong>. All Rights Reserved
                    </div>
                </div>
            </footer >
        </div >
    )

}