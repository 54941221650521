export const authReducer = (state={token : ""}, action)=>{
    switch (action.type){
        case "LOGIN" :
            let obj =  Object.assign({}, state);
            obj.token = action.payload.token;
            obj.email = action.payload.email;
            obj.name = action.payload.name;
            obj.id = action.payload.id;
            return obj;
        case "LOGOUT" :
            return {
                token : "",
                email : "",
                name : ""
            };
        default : 
            return state;
    }
}